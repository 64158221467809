var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('section',{staticClass:"section banner__inner"},[_c('markdown-it-vue',{attrs:{"content":_vm.banner.titlu}})],1),(_vm.slides && _vm.slides.length > 1)?_c('banner-carousel',{attrs:{"slides":_vm.slides}}):_c('div',{staticClass:"banner__image is-relative"},[_c('img',{attrs:{"src":_vm.width > 1000
					? (_vm.databaseLink + "/" + (_vm.banner.imagine.url))
					: _vm.width <= 1000 && _vm.width > 750
					? (_vm.databaseLink + "/" + (_vm.banner.imagine.formats.large.url))
					: _vm.width <= 750 && _vm.width > 500
					? (_vm.databaseLink + "/" + (_vm.banner.imagine.formats.medium.url))
					: _vm.width <= 500
					? (_vm.databaseLink + "/" + (_vm.banner.imagine.formats.small.url))
					: null,"alt":_vm.$route.name}})]),_c('section',{staticClass:"section banner__content has-text-centered"},[_c('markdown-it-vue',{attrs:{"content":_vm.banner.descriere}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }