<template>
	<div class="product has-text-centered is-flex is-flex-direction-column">
		<router-link
			v-if="fromPage === 'home'"
			:to="
				`/produse/produs/${formatProductTitle(product.titlu)}-${
					product.id
				}/`
			"
			class="product__image is-block"
		>
			<img
				height="400"
				:src="`${databaseLink}/${product.imagine.url}`"
				:alt="product.titlu"
			/>
		</router-link>

		<router-link
			v-else
			:to="
				`${$route.path}produse/${formatProductTitle(product.titlu)}-${
					product.id
				}/`
			"
			class="product__image is-block"
		>
			<img
				height="400"
				:src="`${databaseLink}/${product.imagine.url}`"
				:alt="product.titlu"
			/>
		</router-link>
		<div
			class="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center p-3"
			style="height: 100%"
		>
			<router-link
				v-if="fromPage === 'home'"
				:to="
					`/produse/produs/${formatProductTitle(product.titlu)}-${
						product.id
					}/`
				"
				class="product__title is-block mb-4"
			>
				{{ product.titlu }}
			</router-link>
			<router-link
				v-else
				:to="
					`${$route.path}produse/${formatProductTitle(
						product.titlu
					)}-${product.id}/`
				"
				class="product__title is-block mb-4"
			>
				{{ product.titlu }}
			</router-link>
			<strong
				v-if="product.pret && product.pret > 0"
				class="product__price is-block mb-4"
			>
				{{ product.pret }} RON
			</strong>
			<router-link
				v-if="fromPage === 'home'"
				:to="
					`/produse/produs/${formatProductTitle(product.titlu)}-${
						product.id
					}/`
				"
				class="btn btn--primary"
				>Detalii produs</router-link
			>
			<router-link
				v-else
				:to="
					`${$route.path}produse/${formatProductTitle(
						product.titlu
					)}-${product.id}/`
				"
				class="btn btn--primary"
				>Detalii produs</router-link
			>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			product: {
				type: Object,
				require: true,
			},
			fromPage: String,
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
		},
		methods: {
			formatProductTitle(title) {
				let str = title.toLowerCase();
				let charMap = {
					ă: 'a',
					â: 'a',
					î: 'i',
					ş: 's',
					ţ: 't',
				};
				let rx = /(ă|â|î|ş|ţ)/g;
				if (rx.test(str)) {
					str = str.replace(rx, function(m, key) {
						return charMap[key];
					});
				}
				str = str.replace(/[^a-z+\d\s-]/gi, '');
				str = str.replace(/[\s-]+/g, ' ');
				str = str.replace(/\s/g, '-');
				return str;
			},
		},
	};
</script>

<style scoped lang="scss">
	.product {
		box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
		// border: 2px solid rgba($primary, 0.5);
		border-radius: $round-corners;
		transition: border-color 0.3s ease;
		height: 100%;
		&:hover {
			border-color: $primary;
		}
		img {
			height: 250px;
		}
		&__title {
			font-size: 1.25rem;
			text-transform: uppercase;
			font-weight: $medium;
		}
		&__price {
			color: lighten($primary, 20%);
			font-size: 1.125rem;
			font-weight: $semibold;
		}
	}
</style>
