<template>
	<main
		:class="`page-category page-${category.nume}`"
		v-if="category && category !== null"
	>
		<category-banner :banner="category.banner" :slides="category.carusel" />
		<section
			v-if="
				category.nume === 'aloe-vera' &&
					category.caracteristici &&
					category.caracteristici.length > 0
			"
			class="section section--larger"
		>
			<div class="container">
				<h2 class="section__title has-text-centered">
					Informații despre aloea noastră
				</h2>
				<div class="columns is-multiline">
					<div
						class="column is-6-tablet is-4-desktop"
						v-for="feature in category.caracteristici"
						:key="feature.id"
					>
						<div class="feature has-text-centered is-relative">
							<div
								class="feature__icon is-flex is-align-items-center is-justify-content-center"
							>
								<img
									:src="
										`${databaseLink}/${feature.imagine.url}`
									"
									:alt="feature.imagine.alternativeText"
								/>
							</div>
							<h3 class="feature__title mb-3">
								{{ feature.titlu }}
							</h3>
							<p class="feature__description">
								{{ feature.descriere }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section
			v-if="
				category.nume === 'business-alatura-te-echipei' &&
					category.subcategories &&
					category.subcategories.length > 0
			"
			class="section section--larger"
		>
			<div class="container">
				<h2 class="section__title has-text-centered">
					UNDE TE REGĂSEȘTI?
				</h2>
				<div class="columns is-multiline is-centered">
					<component-category
						v-for="subcategory in category.subcategories"
						:key="subcategory.id"
						:category="subcategory"
						type="business"
						@open="openModal($event)"
					/>
				</div>
			</div>
		</section>
		<section class="section section--larger" v-else>
			<div class="container">
				<div class="columns is-multiline is-centered">
					<component-category
						v-for="subcategory in category.subcategories"
						:key="subcategory.id"
						:category="subcategory"
						type="subcategory"
					/>
				</div>
			</div>
		</section>
		<section
			v-if="category.products && category.products.length > 0"
			class="section section__products section--larger"
		>
			<div class="container">
				<h2 class="section__title has-text-centered">
					Produse {{ category.titlu }}
				</h2>
				<div class="columns is-multiline is-centered">
					<div
						class="column is-6-tablet is-4-desktop mb-5"
						v-for="product in highlightedProducts"
						:key="product.id"
					>
						<component-product :product="product" />
					</div>
				</div>
			</div>
		</section>
		<section
			v-if="category.articles && category.articles.length > 0"
			class="section section__articles section--larger"
		>
			<div class="container">
				<h2 class="section__title has-text-centered">
					Articole {{ category.titlu }}
				</h2>
				<div class="columns is-multiline is-centered">
					<div
						class="column is-6-tablet is-4-desktop mb-5"
						v-for="article in highlightedArticles"
						:key="article.id"
					>
						<component-article
							:article="article"
						></component-article>
					</div>
				</div>
			</div>
		</section>
		<component-videos
			v-if="category.videoclipuri && category.videoclipuri.length > 0"
			:title="category.titlu"
			:videos="category.videoclipuri"
		/>
		<testimonial-carousel />
		<component-gallery
			v-if="
				category.galerie_imagini && category.galerie_imagini.length > 0
			"
			:images="images"
		/>
		<component-certificates
			v-if="category.certificates && category.certificates.length > 0"
			:certificates="category.certificates"
		></component-certificates>
		<b-modal
			v-if="category.nume === 'business-alatura-te-echipei'"
			v-model="isOpen"
			trap-focus
			:destroy-on-hide="false"
			aria-role="dialog"
			aria-modal
		>
			<template #default="props">
				<component-business-modal
					v-bind="formProps"
					@close="props.close"
				/>
			</template>
		</b-modal>
	</main>
	<main class="no-content-main" v-else>
		<strong
			>For the moment there is no content in
			{{
				lang === 'EN' && (lang !== null || lang !== 'undefined')
					? 'English'
					: 'this language'
			}}
			for this category. <br />
			Please switch to the other language!</strong
		>
	</main>
</template>

<script>
	import ComponentCategory from '@/components/ComponentCategory.vue';
	import ComponentCertificates from '@/components/ComponentCertificates.vue';
	import ComponentBusinessModal from '@/components/ComponentBusinessModal.vue';
	import CategoryBanner from '@/components/TheCategoryBanner.vue';
	import TestimonialCarousel from '@/components/TheTestimonialCarousel.vue';
	import ComponentProduct from '@/components/ComponentProduct.vue';
	import ComponentArticle from '@/components/ComponentArticle.vue';
	import ComponentVideos from '@/components/ComponentVideos.vue';
	import ComponentGallery from '@/components/ComponentGallery.vue';

	import { mapState } from 'vuex';
	import axios from 'axios';

	export default {
		components: {
			CategoryBanner,
			TestimonialCarousel,
			ComponentBusinessModal,
			ComponentProduct,
			ComponentArticle,
			ComponentVideos,
			ComponentGallery,
			ComponentCategory,
			ComponentCertificates,
		},
		data() {
			return {
				formProps: {
					fieldType: '',
				},
				isOpen: false,
				noContent: false,
				lang: '',
			};
		},
		beforeCreate() {},
		created() {
			const lang = localStorage.getItem('lang');
			this.lang = lang;
			this.$store.dispatch(
				'categories/getCategoryByName',
				this.$route.params.categorie
			);
		},
		computed: {
			...mapState('categories', ['category']),
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			// currentCategory() {
			// 	return this.category;
			// },
			images() {
				return this.category.galerie_imagini.map(
					(img) =>
						`${process.env.VUE_APP_STRAPI_DATABASE_URL}/${img.imagine.url}`
				);
			},
			highlightedProducts() {
				return this.category.products.filter((prod) => {
					return prod.highlight_produs === true;
				});
			},
			highlightedArticles() {
				return this.category.articles.filter((art) => {
					return art.highlight_articol === true;
				});
			},
		},
		methods: {
			openModal(value) {
				this.formProps.label = value;
				this.isOpen = true;
			},
		},
		// watch: {
		// 	category: {
		// 		handler(newVal) {
		// 			if (newVal) {
		// 				console.log(newVal);
		// 			} else {
		// 				this.noContent = true;
		// 			}
		// 		},
		// 	},
		// },
		async beforeMount() {
			let response = await axios.get(
				`${process.env.VUE_APP_STRAPI_DATABASE_URL}/categories/?nume=${this.$route.params.categorie}`
			);
			let category = await response.data[0];

			document.title = `Forever Diamond Teams | ${category.titlu}`;

			const metaTags = [
				{
					name: 'description',
					content: `Forever Diamond Teams | ${category.titlu}`,
				},
				{
					property: 'og:title',
					content: `Forever Diamond Teams | ${category.titlu}`,
				},
				{
					property: 'og:description',
					content: `Forever Diamond Teams | ${category.titlu}`,
				},
				{
					property: 'og:image',
					content: `${process.env.VUE_APP_STRAPI_DATABASE_URL}${category.imagine.url}`,
				},
				{
					property: 'og:url',
					content: `${process.env.VUE_APP_WEBSITE_URL}${category.nume}`,
				},
			];

			Array.from(
				document.querySelectorAll('[data-vue-router-controlled]')
			).map((el) => el.parentNode.removeChild(el));

			metaTags
				.map((tagDef) => {
					const tag = document.createElement('meta');

					Object.keys(tagDef).forEach((key) => {
						tag.setAttribute(key, tagDef[key]);
					});

					tag.setAttribute('data-vue-router-controlled', '');

					return tag;
				})
				.forEach((tag) => document.head.appendChild(tag));

			// next();
		},
	};
</script>

<style scoped lang="scss">
	.no-content-main {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 50vh;
		font-size: 1.25rem;
		text-align: center;
	}
	.banner {
		&__title {
			.quote {
				color: darken($secondary, 10%);
				flex-wrap: wrap;
			}
		}
	}
	.column {
		margin-bottom: 4rem;
	}
	.feature {
		border: 1px solid rgba($yellow, 0.5);
		border-radius: $round-corners;
		padding: 5rem 1rem 1rem;
		height: 100%;
		transition: background-color 0.3s ease;
		&:hover {
			background-color: rgba($yellow, 0.5);
			.feature__icon {
				box-shadow: 0 8px 8px -2px rgba(0, 0, 0, 0.1);
			}
		}
		&__icon {
			background-color: $white;
			border: 1px solid rgba($yellow, 0.5);
			border-radius: 50%;
			box-sizing: content-box;
			line-height: 100px;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%, -50%);
			transition: box-shadow 0.3s ease;
			height: 120px;
			width: 120px;
		}
		&__title {
			font-size: 1.25rem;
			min-height: 52px;
			line-height: 1.3;
		}
	}
</style>
