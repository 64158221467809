<template>
	<div class="banner">
		<section class="section banner__inner">
			<markdown-it-vue :content="banner.titlu" />
		</section>
		<banner-carousel :slides="slides" v-if="slides && slides.length > 1" />
		<div class="banner__image is-relative" v-else>
			<img
				:src="
					width > 1000
						? `${databaseLink}/${banner.imagine.url}`
						: width <= 1000 && width > 750
						? `${databaseLink}/${banner.imagine.formats.large.url}`
						: width <= 750 && width > 500
						? `${databaseLink}/${banner.imagine.formats.medium.url}`
						: width <= 500
						? `${databaseLink}/${banner.imagine.formats.small.url}`
						: null
				"
				:alt="$route.name"
			/>
		</div>
		<section class="section banner__content has-text-centered">
			<markdown-it-vue :content="banner.descriere" />
		</section>
	</div>
</template>

<script>
	import BannerCarousel from '@/components/TheBannerCarousel.vue';

	export default {
		props: {
			banner: {
				type: Object,
				require: true,
			},
			slides: {
				type: Array,
				require: true,
			},
		},
		name: 'SecondaryBanner',
		data() {
			return {
				width: window.innerWidth,
			};
		},
		components: {
			BannerCarousel,
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
		},
	};
</script>

<style scoped lang="scss">
	.banner {
		background-color: lighten($gray, 28%);
		&__image {
			padding-top: 56.25%;
			overflow: hidden;
			img {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 100%;
				height: auto;
			}
		}
	}
</style>
